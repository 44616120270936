* {
  box-sizing: border-box;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6rem rgba(0, 0, 0, 0.3);
  border-radius: 1rem;
  background-color: #b8b8b8;
}

::-webkit-scrollbar {
  width: 6rem;
  background-color: #b8b8b8;
}

::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  -webkit-box-shadow: inset 0 0 6rem rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.container {
  width: 100%;
  padding-right: 20rem;
  padding-left: 20rem;
  margin-right: auto;
  margin-left: auto;
}

// @media (min-width: 576px) {
//   .container {
//     max-width: 540rem;
//   }
// }

@media (min-width: 768px) {
  .container {
    width: 100%;
    padding-right: 48rem;
    padding-left: 48rem;
  }
}

// @media (min-width: 992px) {
//   .container {
//     padding-right: 20rem;
//     padding-left: 20rem;
//     max-width: 960rem;
//   }
// }

@media (min-width: 1200px) {
  .container {
    max-width:100%;
    padding-right: 120rem;
    padding-left: 120rem;
  }
}
