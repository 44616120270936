.teaser-content {
  color: #ffff;
  flex: 1;
  display: none;

  @include respond-to(mobile) {
    animation: content 0.3s forwards 8.3s ease-in;
    padding-top: calc(67rem + 65px);
    opacity: 0;
    display: flex;
  }

  & svg path {
    fill: white;
  }
}

.teaser-content-row {
  display: flex;
  @include respond-to(mobile) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: 38.5rem;
  }
  &__left {
    display: flex;
    flex-direction: column;
    &__content {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 24rem;
      line-height: 28rem;

      & svg {
        margin-left: 6rem;
        width: 24rem;
        height: 24rem;
      }

    }

    @include respond-to(mobile) {
      &__content {
        & svg {
          margin-left: 4rem;
        }
      }
    }
  }

  &__right {
    font-family: Josefin Sans;
    font-style: normal;
    font-weight: 300;
    font-size: 24rem;
    line-height: 24rem;
    color: #ffffff;
    opacity: 0.9;
  }
}

@include respond-to(mobile){
  .teaser-content-row__left__content{
    font-size: 20rem;
    line-height: 23rem;
    color: #ffffff;
  }
}

@keyframes content {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
