.header-items {
  display: flex;
  gap: 18rem;

  &__item {
    width: 40rem;
    height: 40rem;
    background: #FFFFFF;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &.footer{
    display: none;
  }
}
