.start-animation {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 5;
  visibility: visible;
  animation: last 3s forwards 6s;

  
  &__color {
    background: #000;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3;
    animation: bg-color 4s forwards 6s;
  }
  
  &__body {
    width: 100%;
    height: 100%;
    position: relative;
  }
  
  &__bg {
    animation: bg 1s linear forwards 3.2s;
    opacity: 0;
    left: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 4;
    background-image: url('data:image/svg+xml, <svg width="1500" height="982" viewBox="0 0 1500 982" fill="none" xmlns="http://www.w3.org/2000/svg"><line opacity="0.2" x1="0.5" x2="0.5" y2="982" stroke="white" /><line opacity="0.2" x1="302.5" x2="302.5" y2="982" stroke="white" /><line opacity="0.2" x1="604.5" x2="604.5" y2="982" stroke="white" /><line opacity="0.2" x1="906.5" x2="906.5" y2="982" stroke="white" /><line opacity="0.2" x1="1208.5" x2="1208.5" y2="982" stroke="white" /></svg>');
    background-size: contain;
    background-position: center;
  }
  
  @include respond-to(tablet) {
    &__bg{
      background-image: url('data:image/svg+xml, <svg width="1500" height="982" viewBox="0 0 1500 982" fill="none" xmlns="http://www.w3.org/2000/svg"><line opacity="0.2" x1="0.5" x2="0.5" y2="982" stroke="white" /><line opacity="0.4" x1="302.5" x2="302.5" y2="982" stroke="white" /><line opacity="0.4" x1="604.5" x2="604.5" y2="982" stroke="white" /><line opacity="0.4" x1="906.5" x2="906.5" y2="982" stroke="white" /><line opacity="0.4" x1="1208.5" x2="1208.5" y2="982" stroke="white" /></svg>');
    }
    overflow-y: unset;
  }

  &__first {
    opacity: 0;
    animation: first 3s linear forwards 1s;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    & img{
      width: 780rem;
      height: 300rem;
    }

    &__text {
      margin-bottom: 31rem;
      text-align: center;
      & svg {
        width: 885rem;
        height: 66rem;
      }
    }
    &__svg {
      text-align: center;
      & svg {
        width: 170rem;
        height: 185rem;
      }
    }
    @include respond-to(tablet) {
      & img{
        width: 440rem;
        height: 160rem;
        image-rendering: -webkit-optimize-contrast;

      }
      &__text {
        margin-bottom: 31rem;
        & svg {
          width: 500rem;
          height: 40rem;
        }
      }
      &__svg {
        text-align: center;
        & svg {
          width: 120rem;
          height: 135rem;
        }
      }
    }
    @include respond-to(mobile) {
      & img{
        width: 250rem;
        height: 100rem;
      }
      &__text {
        margin-bottom: 21rem;
        & svg {
          width: 250rem;
          height: 30rem;
        }
      }
      &__svg {
        text-align: center;
        & svg {
          width: 70rem;
          height: 90rem;
        }
      }
    }
  }

  &__second {
    animation: second 1s linear forwards 3.1s;
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &__text {
      & svg {
        width: 717rem;
        height: 149rem;
      }
    }

    @include respond-to(tablet) {
      &__text {
        & svg {
          width: 457rem;
          height: 95rem;
        }
      }
    }
    @include respond-to(mobile) {
      &__text {
        & svg {
          width: 280rem;
          height: 70rem;
        }
      }
    }
  }
}

@keyframes first {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  75% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  90% {
    opacity: 0;
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

@keyframes second {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes bg {
  from {
    opacity: 1;
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes last {
  from {
    opacity: 1;
  }
  75% {
    background: transparent;
    opacity: 0;
  }
  100% {
    background: transparent;
    opacity: 0;
    transform: translateY(-50%);
    visibility: hidden;
  }
}

@keyframes bg-color {
  from {
    opacity: 1;
  }
  75% {
    background: transparent;
  }
  100% {
    background: transparent;
    opacity: 0;
    visibility: hidden;
  }
}
