@import "./vendor";
@import "./variables";
@import "./mixins";
@import "./pages/teaser/teaser";
@import "./pages/teaser/header";
@import "./pages/teaser/headerItems";
@import "./pages/teaser/content";
@import "./pages/teaser/footer";
@import "./pages/teaser/footerTop";
@import "./pages/teaser/footerSubscribe";
@import "./pages/teaser/connectBtn";
@import "./pages/teaser/modal";
@import "./pages/teaser/startAnimation";

html {
  font-size: 0.066138vw;
  overflow: hidden;
  // animation: over 0.1s forwards 6.3s;

  @include respond-to(tablet) {
    font-size: 0.13021vw;
  }
  @include respond-to(mobile) {
    font-size: 0.266667vw;
  }
}

body {
  margin: 0;
  font-family: "Raleway", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000;
  overflow: hidden;
  // animation: over 0.1s forwards 6.3s;
}

a {
  text-decoration: none;
  color: #fff;
}
#root,
body,
html,
.teaser,
.teaser__video {
  height: 100%;
}

button {
  cursor: pointer;
}

// @keyframes over {
//   from{
//     overflow: hidden;
//   }
//   to{
//     overflow: unset;
//   }
// }
