header {
  position: fixed;
  width: 100%;
  z-index: 1;
  top: 0;
}

.teaser-header {
  padding-top: 56rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  transform: translateY(-100%);
  animation: header 0.35s forwards 9s ease-in;

  &__info {
    font-family: "Josefin Sans";
    display: inline-block;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: max-content;
    font-weight: 300;
    font-size: 24rem;
    line-height: 24rem;
    color: #ffffff;
    opacity: 0.9;
  }

  &__items {
    display: flex;
    gap: 24rem;
  }

  &__logo {
    width: 203rem;
    height: 68rem;
    object-fit: fill;
  }

  @include respond-to(landscape) {
  }

  @include respond-to(tablet) {
    &__info {
      top: 68rem;
      font-size: 20rem;
      width: fit-content;
    }

    &__logo {
      width: 163rem;
      height: 54rem;
      image-rendering: -webkit-optimize-contrast;
    }
  }
  @include respond-to(mobile) {
    &__info {
      display: none;
      top: 45rem;
      font-size: 14rem;
    }

    &__logo {
      width: 130rem;
      height: 44rem;
    }
    
    padding-top: 30rem;
  }
}

@keyframes header {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}
