.teaser {
  position: relative;
  display: flex;
  flex-direction: column;

  &__video {
    position: absolute;
    inset: 0 0 0 0;
    z-index: -1;
    width: 100%;
    object-fit: cover;
    animation: video 1s forwards 6s linear;
    transform: translateY(10%);

    @media (min-width: 2100px) and (max-height: 1400px) {
      object-fit: contain;
    }
    @media (min-width: 2000px) and (max-height: 950px) {
      object-fit: contain;
    }
    @include respond-to(tablet) {
      object-position: bottom center;
    }
  }
}

@keyframes video {
  from {
    transform: translateY(10%);
  }

  to {
    transform: translateY(0);
  }
}

