.teaser-footer-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffff;

  &__title {
    display: block;
    font-size: 14rem;
    line-height: 16rem;
    color: #ffffff;
    opacity: 0.6;
  }

  &__content {
    font-family: "Josefin Sans";
    margin-top: 4rem;
    display: block;
    font-weight: normal;
    font-size: 16rem;
    line-height: 19rem;
  }

  @include respond-to(tablet) {
    flex-direction: column;
    align-items: flex-start;
    gap: 28rem;
    &__title {
    }

    &__content {
      margin-top: 4rem;
      display: block;
      font-weight: normal;
      font-size: 14rem;
      line-height: 16rem;
    }
  }

  @include respond-to(mobile) {
    justify-content: start;
    gap: 30rem;
    align-items: flex-start;
    flex-direction: column;

    &__left{
      display: none;
    }

    & > div {
      width: 100%;
    }

    &__title {
      text-align: center;
    }

    &__content {
      font-size: 12rem;
      line-height: 12rem;
    }
  }
}
