.teaser-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 23rem 0;
  background: rgba(0, 0, 0, 0.8);
  left: 0;
  bottom: 0;
  transform: translateY(100%);
  animation: footer 0.35s forwards 9s ease-in;

  &__divider {
    height: 1rem;
    background: #eeeeee;
    margin-top: 48rem;
    margin-bottom: 27rem;
    opacity: 0.3;
  }

  & svg path{
    fill: white;
  }

  &__info {
    font-style: normal;
    font-weight: normal;
    font-size: 14rem;
    line-height: 16rem;
    color: #ffffff;
    opacity: 0.5;
    text-align: center;
  }

  @include respond-to(tablet) {
  }


  @include respond-to(mobile) {
    padding-bottom: 30rem;
  }


}

h1{
  color: white;
  font-size: 100rem;
}

@keyframes footer {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}
