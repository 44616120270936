.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
  z-index: 9999;
  color: #fff;
}

.modal.enter-done {
  opacity: 1;
  pointer-events: visible;
}

.modal.exit {
  opacity: 0;
}

.modal.enter-done .modal-border {
  transform: translateY(0);
}

.modal.exit .modal-border  {
  transform: translateY(-200rem);
}

.modal-content {
  width: 884rem;
  background: url("../../../images/modal-bg.png");
  background-color: #000;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 45rem 70rem 36rem;

  @include respond-to(tablet) {
    width: 652rem;
    padding: 51rem 36rem 21rem;
  }
  @include respond-to(mobile) {
    width: 340rem;
    padding: 26rem 20rem 33rem;

  }
}

.modal-border {
  transition: all 0.3s ease-in-out;
  transform: translateY(-200rem);
  padding: 18rem;
  border: 3rem solid rgba(255, 255, 255, 0.4);

  @include respond-to(tablet) {
    padding: 14rem 14rem;
  }
}

.modal-title {
  font-weight: normal;
  font-size: 36rem;
  line-height: 42rem;
  margin-bottom: 41rem;

  @include respond-to(tablet) {
    margin-bottom: 33rem;
  }
  @include respond-to(mobile) {
    margin-bottom: 18rem;
  }
}

.modal-body {
  font-weight: normal;
  font-size: 24rem;
  line-height: 28rem;
  margin-bottom: 36rem;

  @include respond-to(tablet) {
    margin-bottom: 52rem;
    line-height: 32rem;
  }
  @include respond-to(mobile) {
    margin-bottom: 33rem;
    font-size: 16rem;
  }
}

.modal-btn {
    border: none;
    outline: none;
    color: #000000;
    background: #FFFFFF;
    padding: 16rem 24rem;
    @include respond-to(mobile){
        padding: 12rem 16rem;
    
    }
}
