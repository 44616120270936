.footer-subscribe {
  &__input {
    width: 400rem;
    height: 70rem;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(50rem);
    border: none;
    outline: none;
    padding-left: 30rem;
    font-size: 16rem;
    color: #ffffff;

    &::placeholder {
      font-weight: normal;
      font-size: 16rem;
      line-height: 19rem;
      color: #ffffff;
    }
  }

  &__part {
    display: flex;
    gap: 8rem;
  }

  &__btn {
    width: 160rem;
    height: 70rem;
    border-radius: 0;
    font-weight: 600;
    color: rgba(0, 0, 0, 1);
    font-size: 18rem;
    line-height: 21rem;
    border: none;
    transition: all 0.1s linear;
    &:active {
      box-shadow: inset 0 0 5rem #000000;
    }
  }

  &__info {
    display: block;
    font-weight: normal;
    font-size: 14rem;
    line-height: 16rem;
    color: #ffffff;
    opacity: 0.8;
    margin-top: 5rem;
  }

  @include respond-to(tablet) {
    width: calc(100vw - 96rem);
    &__input {
      flex: 1;
      height: 51rem;
      padding-left: 25rem;
      font-size: 14rem;

      &::placeholder {
        font-size: 14rem;
        line-height: 18rem;
      }
    }
    &__btn {
      width: 160rem;
      height: 51rem;
      font-size: 14rem;
      line-height: 18rem;
    }

    &__info {
      display: block;
      font-weight: normal;
      font-size: 12rem;
      line-height: 16rem;
      color: #ffffff;
      opacity: 0.8;
      margin-top: 8rem;
    }
  }

  @include respond-to(mobile) {
    width: calc(100vw - 40rem);
    &__part {
      flex-direction: column;
    }
    &__input {
      flex: none;
      width: 100%;
      height: 51rem;
      padding-left: 15rem;
      font-size: 14rem;
      &::placeholder {
        font-size: 14rem;
      }
    }
    &__btn {
      width: 100%;
      height: 51rem;
      font-weight: 600;
      font-size: 16rem;
      line-height: 19rem;
    }

    &__info {
      display: block;
      font-weight: normal;
      font-size: 14rem;
      line-height: 16rem;
      color: #ffffff;
      opacity: 0.8;
      text-align: center;
    }
  }
}
