.connect-btn {
  padding: 16rem 22rem;
  background: #ffffff;
  color: rgba(0, 0, 0, 1);
  font-weight: 600;
  font-size: 16rem;
  line-height: 19rem;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.1s ease-in;

  &:active {
    box-shadow: inset 0 0 5rem #000000;
  }

  @include respond-to(mobile) {
    padding: 12rem 15rem;
    font-weight: 600;
    font-size: 14rem;
    line-height: 16rem;
    text-transform: uppercase;
  }
}
